<app-text-search
  [lightInput]="true"
  btnText="Search jobs"
  miniPlaceholder="Search by job title, skill or company"
  placeholder="Search by job title, skill or company"
  [searchBtnColor]="searchBtnColor"
  [alwaysMini]="alwaysMini"
  [control]="searchControl"
  (inputFieldFocusEvent)="onInputFieldFocusEvent()"
  [searchQuery]="searchQuery"
  (clear)="handleClear()"
  [leadingIconSrc]="leadingIconSrc"
  (doSearch)="doSearchFn($event)" />
